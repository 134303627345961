(function($) {

  var mainMenu = function() {

    var $mainMenuContainer = $('#js-main-menu-container');
    var $mainMenuToggle = $('.js-main-menu-toggle');
    var $mainMenu = $('#js-main-menu');

    $mainMenu.attr('aria-expanded', 'false');

    var closeMenu = function() {
      $mainMenuContainer.removeClass('is-toggled');
      $mainMenuToggle.attr('aria-expanded', 'false').removeClass('is-active');
      $mainMenu.attr('aria-expanded', 'false');
    };

    /* Toggle menu */
    $mainMenuToggle.on('click', function(e) {

      e.preventDefault();
      e.stopImmediatePropagation();

      $mainMenuContainer.fadeToggle(250);

      if ($mainMenuContainer.is('.is-toggled')) {
        closeMenu();
      } else {
        $mainMenuContainer.addClass('is-toggled');
        $mainMenuToggle.attr('aria-expanded', 'true').addClass('is-active');
        $mainMenu.attr('aria-expanded', 'true');
      }

    });

    $('#js-main-menu .menu-item-has-children > a').on('click', function(e) {
      if (window.matchMedia("(min-width: 960px)").matches) {
        e.preventDefault();
        $this = $(this);
        $this.toggleClass('st-menu-open');
        $this.siblings('.sub-menu').fadeToggle(100);
      }
    });

    /* Close menu when a click is made elsewhere on the page */
    $(document).on('click', function(e) {
      if (window.matchMedia("(min-width: 960px)").matches) {
        $('#js-main-menu .menu-item-has-children > a').each(function() {
          $this = $(this);
          $menu = $this.siblings('.sub-menu');
          menuArea = $(e.target).closest($menu).length;
          toggleArea = $(e.target).closest($this).length;
          if ($this.hasClass('st-menu-open')) {
            if(!menuArea && !toggleArea) {
              $this.removeClass('st-menu-open');
              $this.siblings('.sub-menu').fadeToggle(100);
            }
          }
        });
      }
    });

  };

  mainMenu();

  var anchorLink = function() {

    $('.js-anchor-link').on('click', function(e) {

      e.preventDefault();
      var section = $(this).attr('href');
      var headerHeight = $('#js-site-header').outerHeight();
      var scrollDistance = $(section).offset().top - headerHeight;

      if($mainMenuContainer.is('.is-toggled') && $mainMenuToggle.is(':visible')) {
        closeMenu();
        $mainMenuContainer.fadeOut(250);
      }

      $('html, body').stop().animate({
        scrollTop: scrollDistance
      }, 800);

    });

  };

  anchorLink();

})(jQuery); // Fully reference jQuery after this point.
