/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var headerSize = function() {
          var scrollTop = $(window).scrollTop();
          if(scrollTop > 50) {
            $('body').addClass('st-small-header');
          } else {
            $('body').removeClass('st-small-header');
          }
        };

        headerSize();

        $(window).on('scroll resize', function() {
          headerSize();
        });

        $('.js-stick-in-parent').stick_in_parent({
          offset_top: 130
        });

        /*
        $('.js-stick-in-modal').each(function() {
          $(this).stick_in_parent({
            parent: $(this).closest('.js-modal')
          });
        });
        */

        $.fancybox.defaults.animationEffect = "fade";
        $.fancybox.defaults.buttons = [
          "thumbs",
          "close"
        ];

        var faqAccordion = function() {

          $('.js-faq-btn').each(function() {

            var $menuBtn = $(this);
            var $menu = $menuBtn.siblings('.js-faq-content');

            /* Toggle menu */
            $menuBtn.on('click', function(e) {

              e.preventDefault();
              e.stopImmediatePropagation();

              if ($menu.is('.is-open')) {
                $menuBtn.attr('aria-expanded', 'false').removeClass('is-open');
                $menu.slideUp(250, function() {
                  $menu.removeClass('is-open');
                  $menu.attr('aria-expanded', 'false');
                });
              } else {
                $menuBtn.attr('aria-expanded', 'true').addClass('is-open');
                $menu.slideDown(250, function() {
                  $menu.addClass('is-open');
                  $menu.attr('aria-expanded', 'true');
                });
              }

            });

          });

        };

        faqAccordion();

        if($('#js-filter-response').length) {
          $('#js-filter-response').masonry();
        }

        if(($('.js-form-serial-nr').length) && ($('#js-reg-form').length)) {
          var serial = $('#js-reg-form').attr('data-serial');
          $('.js-form-serial-nr').val(serial);
        }

        var modal = function() {

          $('.js-open-modal').on('click', function(e) {
            e.preventDefault();
            var target = '#' + $(this).attr('data-modal');
            $(target).css('display', 'flex').hide().fadeIn(200, function() {
              $('body').addClass('st-modal-open');
            });
          });

          $('.js-close-modal').on('click', function() {
            $(this).closest('.js-modal').fadeOut(200, function() {
              $('body').removeClass('st-modal-open');
            });
          });

          /* Close menu when a click is made elsewhere on the page */
          $(document).on('click', function(e) {
            modalArea = $(e.target).closest('.js-modal-box').length;
            if ($('body').hasClass('st-modal-open')) {
              if(!modalArea) {
                $('.js-modal').fadeOut(200, function() {
                  $('body').removeClass('st-modal-open');
                });
              }
            }
          });

        };

        modal();

        var ajaxModal = function() {
          var $button = $('.js-open-ajax-modal');
          var $modal = $('.js-modal');
          var $modal_target = $('#js-modal-body');
          $button.on('click', function(e) {
            e.preventDefault();
            $modal_target.html('<div class="u-loader-wrapper js-loader"><div class="u-lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            $modal.css('display', 'flex').hide().fadeIn(200);
            var id = $(this).attr('data-id');
            $.ajax({
              url: ajax_params.ajax_url,
              data: {
                'action': 'fetch_modal_content',
                'id': id
              },
              success: function(data) {
                $modal_target.fadeOut(200, function() {
                  $modal_target.html(data).fadeIn(200);
                });
              }
            });
            return false;
          });
        };

        ajaxModal();

        var ajaxFilter = function() {
          $('#js-loader-container').addClass('st-loading');
          var filter = $('#js-filter');
          $.ajax({
            url: ajax_params.ajax_url,
            data: filter.serialize(),
            dataType: 'json',
            type: 'POST',
            beforeSend: function(xhr){
            },
            success: function(data){
              $('#js-loader-container').removeClass('st-loading');
              $('#js-filter-response').masonry('destroy').html(data.content).masonry();
            }
          });
          return false;
        };

        $('.js-filter-input').on('change', function(){
          ajaxFilter();
        });

        $('.js-form-type-input').on('change', function(){
          $form_type = $(this).val();
          $('.js-form-container.st-active').hide().removeClass('st-active');
          $('.js-form-side.st-active').hide().removeClass('st-active');
          $('#' + $form_type).fadeIn(200).addClass('st-active');
          $('[data-form-id="'+ $form_type +'"]').fadeIn(200).addClass('st-active');
        });

        $('.js-course-type-input').on('change', function(){
          $course_type = $(this).val();
          $('.js-course-container.st-active').hide().removeClass('st-active');
          $('#' + $course_type).fadeIn(200).addClass('st-active');
        });

        // Close modal when a click is made elsewhere on the page
        /*
        $(document).on('click', function(e) {
          if ($('.js-modal').is(':visible')) {
            modalArea = $(e.target).closest($('.js-modal-box')).length;
            if(!modalArea) {
              $('.js-modal').fadeOut(200);
            }
          }
        }); */

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
